<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="d-flex">
      <v-card-text class="d-flex justify-center">
        <v-progress-linear
          v-if="showQrcode === false"
          color="red lighten-2"
          buffer-value="0"
          stream
        />
        <qrcode-vue
          v-else
          :value="token"
          :size="size"
          level="H"
        />
      </v-card-text>
    </v-card>
    <!-- data ocr -->
    <v-dialog
      v-model="dataPopup"
      persistent
      max-width="600px"
    >
      <v-card class="pt-3 mt-5">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-row class="align">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <span class="font-weight-bold">{{
                      $t("messages.name")
                    }}</span>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-text-field
                      v-model="data.name"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row class="align">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <span class="font-weight-bold">{{
                      $t("messages.dob")
                    }}</span>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-text-field
                      v-model="data.dob"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row class="align">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <span class="font-weight-bold">{{
                      $t("messages.sex")
                    }}</span>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-text-field
                      v-model="data.sex"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row class="align">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <span class="font-weight-bold">{{
                      $t("messages.language")
                    }}</span>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-text-field
                      v-model="data.language"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row class="align">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <span class="font-weight-bold">{{
                      $t("messages.visaId")
                    }}</span>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-text-field
                      v-model="data.visaId"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row class="align">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <span class="font-weight-bold">{{
                      $t("table.headers.address")
                    }}</span>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-text-field
                      v-model="data.contactAddress"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row class="align">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <span class="font-weight-bold">{{
                      $t("messages.residenceCardId")
                    }}</span>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-text-field
                      v-model="data.residenceCardId"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row class="align">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <span class="font-weight-bold">{{
                      $t("messages.residenceCardValidDate")
                    }}</span>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-text-field
                      v-model="data.residenceCardValidDate"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            large
            @click="saveData"
          >
            {{ $t("button.save") }}
          </v-btn>
          <v-btn
            color="dark darken-1"
            large
            @click="dataPopup = false"
          >
            {{ $t("button.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import { get } from 'vuex-pathify'
  import QrcodeVue from 'qrcode.vue'
  import moment from 'moment'
  export default {
    components: {
      QrcodeVue,
    },
    data () {
      return {
        size: 300,
        showQrcode: false,
        dataPopup: false,
        data: {},
        polling: null,
      }
    },
    computed: {
      ...get('parttimer', ['token', 'dataOcr']),
    },
    watch: {
      token (value) {
        if (value.length > 0) {
          this.showQrcode = true
          this.polling = setInterval(() => {
            this.$store.dispatch('parttimer/getDataOcr')
          }, 5000)
        }
      },
      dataOcr (value) {
        if (value.success === true) {
          this.dataPopup = true
          clearInterval(this.polling)
          this.data.name = value.Name
          this.data.dob = moment(value.Birthday, 'YYYY-MM-DD').format('YYYY-MM-DD')
          this.data.sex = value.Sex
          this.data.residenceCardId = value.CardId
          this.data.residenceCardValidDate = moment(value.Period, 'YYYY-MM-DD').format('YYYY-MM-DD')
          this.data.language = value.Region
          this.data.visaId = value.Status
        }
        if (value.success === false) {
          clearInterval(this.polling)
        }
      },
    },
    created () {
      this.$store.dispatch('parttimer/getTokenOcr')
      this.$store.dispatch('parttimer/saveDataOcr', {})
    },
    mounted () {
      setTimeout(function () { clearInterval(this.polling) }.bind(this), 300000)
    },
    methods: {
      saveData () {
        this.$store.commit('parttimer/dataOcr', {})
        this.$store.dispatch('parttimer/saveDataOcr', this.data)
        this.dataPopup = false
      },
    },
  }
</script>
